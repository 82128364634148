@media screen and (min-device-width: 481px) {
  .form-container {
    padding: 10px 10px 0px 10px;
    margin: 0 !important;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap; }
    .form-container .form-col {
      width: fit-content;
      display: flex;
      align-items: center;
      margin-right: 12px; }
      .form-container .form-col .algolia-places {
        width: 150px; }
    .form-container .button-bar {
      margin-top: 10px;
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: flex-end;
      padding-right: 15px; }
      .form-container .button-bar .approve {
        margin-left: 15px; }
        .form-container .button-bar .approve svg {
          height: 30px;
          width: 30px; }
      .form-container .button-bar .close svg {
        height: 20px;
        width: 20px; } }
