@media screen and (max-device-width: 480px) {
  .categories-container {
    overflow-y: auto;
    flex-wrap: wrap;
    flex-direction: column;
    height: auto;
    padding: 32px 24px; }
    .categories-container .category-col {
      padding: 0;
      width: 100%;
      margin-bottom: 16px; }
  .categories-row {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    border: 2px solid red; } }
