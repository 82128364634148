@media screen and (min-device-width: 481px) {
  .not-found-container {
    height: calc(100vh - 166px);
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; }
    .not-found-container .not-found-image {
      height: 200px;
      width: 200px;
      margin-bottom: 50px; }
    .not-found-container .not-found-text {
      color: var(--error-box); } }
