@media screen and (max-device-width: 900px) {
  .wrapper {
    display: block;
    margin: 0; }
  .error-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    position: relative;
    height: 100%;
    padding-bottom: 22px; }
  .right-background {
    display: flex;
    flex-direction: column;
    background: #f2eff2;
    padding-top: 32px;
    padding-right: 22px;
    padding-left: 22px;
    /* height: calc(100% - 88px); */
    position: absolute;
    top: 88px;
    bottom: 0;
    left: 0;
    right: 0; }
    .right-background .checklist-form {
      margin-top: 30px;
      margin-right: 0 !important;
      margin-left: 0 !important; }
    .right-background .checklistBtnCol {
      bottom: 22px;
      left: 0;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center; }
      .right-background .checklistBtnCol .checklist-btn {
        font-size: 18px;
        padding: 0 32px;
        height: 58px;
        width: 316px; }
    .right-background .form-title {
      margin-bottom: 22px; }
  .left-background {
    background: var(--main-yellow);
    max-width: 544px;
    max-height: 100vh;
    overflow: hidden; }
    .left-background .header {
      height: 298px;
      padding-right: 32px;
      padding-left: 48px; }
      .left-background .header .title {
        width: 100%;
        font-size: 26px;
        line-height: 1.62; }
    .left-background .icons-guide {
      height: 174px;
      background-color: var(--grape-purple); }
      .left-background .icons-guide .icon {
        height: 40px; }
      .left-background .icons-guide .title {
        font-weight: bold;
        color: var(--main-yellow);
        line-height: 1.57;
        letter-spacing: 0.5px;
        margin-top: 30px; } }
