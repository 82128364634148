@media screen and (min-device-width: 481px) {
  .categories-container {
    overflow-y: auto;
    flex-wrap: nowrap;
    height: calc(100vh - 340px);
    padding: 32px 24px 12px 24px; }
    .categories-container .categories-wrap {
      display: flex; }
    .categories-container .category-col {
      display: flex;
      max-height: 100%; }
    .categories-container::-webkit-scrollbar-button {
      display: block;
      height: 4px;
      width: 4px; }
    .categories-container::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 6px;
      height: 12px; }
    .categories-container::-webkit-scrollbar-track-piece {
      background: rgba(0, 0, 0, 0.15);
      border-radius: 3px; }
    .categories-container::-webkit-scrollbar-thumb {
      cursor: pointer;
      border-radius: 3px;
      background: var(--main-purple);
      -webkit-transition: color .2s ease;
      transition: color .2s ease; }
    .categories-container::-webkit-scrollbar-thumb:window-inactive {
      background: rgba(117, 50, 150, 0.5); }
    .categories-container::-webkit-scrollbar-thumb:hover {
      background: var(--main-purple);
      opacity: 0.5; }
  .categories-container.question-row-collapsed {
    height: calc(100vh - 240px) !important; }
  .categories-container.height-transition {
    transition: height 0.1s ease-out; }
  .categories-row {
    width: 100%;
    overflow-x: visible;
    display: flex;
    flex-wrap: nowrap;
    border: 2px solid red; } }
