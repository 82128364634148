.card-container {
  display: flex;
  flex-direction: column; }
  .card-container.loading {
    opacity: 0.5; }
  .card-container .info-container {
    display: flex; }
    .card-container .info-container .data-card {
      flex: 1;
      display: flex;
      flex-direction: column; }
      .card-container .info-container .data-card:first-child .flight {
        border-right: 2px solid #e9e7eb; }
      .card-container .info-container .data-card:first-child .weather {
        border-right: 2px solid #e9e7eb; }
      .card-container .info-container .data-card .flight {
        display: flex;
        flex-direction: column;
        padding: 18px 22px 17px 22px;
        border-bottom: 2px solid #e9e7eb; }
        .card-container .info-container .data-card .flight .top-line {
          display: flex;
          align-items: center; }
          .card-container .info-container .data-card .flight .top-line .flag {
            margin-right: 14px;
            width: 61px;
            height: 46px; }
          .card-container .info-container .data-card .flight .top-line .airport-code {
            font-weight: bold;
            margin-right: auto; }
        .card-container .info-container .data-card .flight .address {
          display: flex;
          flex-direction: column;
          margin-top: 14px; }
          .card-container .info-container .data-card .flight .address .city {
            margin-bottom: 7.5px; }
      .card-container .info-container .data-card .weather {
        display: flex;
        flex: 1;
        align-items: center;
        padding: 12.5px 22px 12.5px 22px;
        justify-content: space-between; }
        .card-container .info-container .data-card .weather .weatherIcon {
          width: 25px;
          height: 25px; }
  .card-container .accommodation-container {
    display: flex;
    min-height: 200px; }
    .card-container .accommodation-container .accommodation-info {
      border-right: 2px solid #e9e7eb;
      width: 75%;
      display: flex;
      flex-direction: column; }
      .card-container .accommodation-container .accommodation-info .accomodation-header {
        flex: 1;
        border-bottom: 2px solid #e9e7eb;
        padding: 18px 24px;
        display: flex;
        justify-content: space-between;
        align-items: center; }
        .card-container .accommodation-container .accommodation-info .accomodation-header .accomodation-title {
          display: flex;
          flex-direction: column;
          align-items: flex-start; }
          .card-container .accommodation-container .accommodation-info .accomodation-header .accomodation-title .title {
            margin-bottom: 10px; }
        .card-container .accommodation-container .accommodation-info .accomodation-header .rating {
          display: flex; }
          .card-container .accommodation-container .accommodation-info .accomodation-header .rating .star {
            height: 10px;
            width: 10px;
            border-radius: 50px;
            border: solid 2px var(--main-pink);
            background-color: var(--main-pink);
            margin-right: 5px; }
            .card-container .accommodation-container .accommodation-info .accomodation-header .rating .star:last-child {
              margin-right: 0; }
          .card-container .accommodation-container .accommodation-info .accomodation-header .rating .star.empty {
            background-color: transparent; }
      .card-container .accommodation-container .accommodation-info .weather {
        display: flex;
        padding: 12.5px 22px 12.5px 22px;
        border-bottom: 2px solid #e9e7eb; }
      .card-container .accommodation-container .accommodation-info .attractions {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 15px 22px; }
        .card-container .accommodation-container .accommodation-info .attractions p {
          margin: 0; }
  .card-container .suggestion-container {
    display: flex;
    min-height: 200px; }
    .card-container .suggestion-container .suggestion-info {
      width: 75%;
      display: flex;
      flex-direction: column;
      border-right: 2px solid #e9e7eb; }
      .card-container .suggestion-container .suggestion-info .suggestion-title {
        padding: 18px 24px;
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        border-bottom: 2px solid #e9e7eb; }
        .card-container .suggestion-container .suggestion-info .suggestion-title .title {
          margin-bottom: 10px; }
      .card-container .suggestion-container .suggestion-info .pricing {
        padding: 15px 22px;
        display: flex;
        align-items: center; }
        .card-container .suggestion-container .suggestion-info .pricing .currency-icon {
          margin-left: auto; }
  .card-container .map-container {
    position: relative;
    width: 25%; }

@media screen and (max-device-width: 480px) {
  .card-container .info-container {
    flex-direction: column; }
    .card-container .info-container .data-card:first-child .weather {
      border-bottom: 4px solid #e9e7eb; }
    .card-container .info-container .data-card:first-child .flight {
      border-right: none; }
    .card-container .info-container .data-card .weather {
      padding: 14px 22px 14px 22px; }
  .card-container .accommodation-container {
    min-height: 0;
    flex-direction: column; }
    .card-container .accommodation-container .accommodation-info {
      width: 100%;
      border: none; }
      .card-container .accommodation-container .accommodation-info .accomodation-header .accomodation-title .title {
        color: var(--main-purple) !important; }
      .card-container .accommodation-container .accommodation-info .weather {
        margin-top: 10px;
        padding: 14px 22px 19px 22px; }
  .card-container .suggestion-container {
    flex-direction: column;
    min-height: none; }
    .card-container .suggestion-container .suggestion-info {
      width: 100%; }
  .card-container .map-container {
    border-bottom: 2px solid #e9e7eb;
    height: 78px;
    width: 100%; } }
