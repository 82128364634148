@media screen and (min-device-width: 901px) {
  .wrapper {
    margin: 0;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0; }
  .error-wrapper {
    display: flex;
    align-items: center;
    position: relative;
    height: 75px; }
  .right-background {
    background: #f2eff2;
    padding-top: 32px;
    padding-right: 32px;
    padding-left: 48px;
    min-height: 100vh; }
    .right-background .checklist-form {
      margin-top: 30px;
      padding-left: 8px; }
    .right-background .checklist-btn {
      position: absolute;
      left: 0px;
      top: 78px;
      opacity: 1;
      pointer-events: all;
      transition: background-color 0.4s ease;
      background-color: var(--main-blue);
      font-size: 18px;
      padding: 0 32px;
      height: 58px; }
    .right-background .fade {
      transition: none;
      background-color: rgba(42, 183, 222, 0.403);
      pointer-events: none;
      box-shadow: none; }
  .left-background {
    background: var(--main-yellow);
    max-width: 544px;
    min-width: 500px;
    max-height: 100vh;
    overflow: hidden; }
    .left-background .header {
      height: 298px;
      padding-right: 32px;
      padding-left: 48px; }
      .left-background .header .title {
        width: 100%;
        font-size: 26px;
        line-height: 1.62; }
    .left-background .icons-guide {
      height: 174px;
      background-color: var(--grape-purple); }
      .left-background .icons-guide .icon {
        height: 40px; }
      .left-background .icons-guide .title {
        font-weight: bold;
        color: var(--main-yellow);
        line-height: 1.57;
        letter-spacing: 0.5px;
        margin-top: 30px; } }
