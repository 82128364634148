.container {
  border: solid 1px #979797;
  background-color: white;
  min-width: 375px;
  max-width: 50vw; }
  .container.is-form {
    width: auto; }
  .container .delete-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 15px 20px; }
    .container .delete-container span {
      text-align: center; }
    .container .delete-container .buttons {
      width: 100%;
      margin-top: 25px;
      display: flex;
      align-items: center;
      justify-content: space-between; }
  .container .new-event-container {
    display: flex;
    height: 350px;
    flex-direction: column; }
    .container .new-event-container .header {
      height: 10%;
      padding: 10px 0;
      display: flex;
      align-items: center;
      justify-content: center;
      border-bottom: solid rgba(151, 151, 151, 0.15); }
    .container .new-event-container .content-container {
      height: 90%;
      position: relative; }
      .container .new-event-container .content-container .select-event-container {
        display: flex;
        flex-wrap: wrap;
        height: 100%;
        width: 100%; }
        .container .new-event-container .content-container .select-event-container .button {
          border-radius: 0;
          font-size: 1.2em;
          height: 50%;
          width: 50%;
          transition: all .20s ease-in-out; }
          .container .new-event-container .content-container .select-event-container .button:hover {
            color: white;
            background-color: var(--main-blue);
            transition: all .20s ease-in-out; }
        .container .new-event-container .content-container .select-event-container .hor-divider {
          position: absolute;
          box-sizing: border-box;
          top: 50%;
          width: 100%;
          height: 1px;
          border: solid 1px rgba(151, 151, 151, 0.1); }
        .container .new-event-container .content-container .select-event-container .ver-divider {
          position: absolute;
          box-sizing: border-box;
          right: 50%;
          height: 100%;
          width: 1px;
          border: solid 1px rgba(151, 151, 151, 0.1); }
  .container .weather-container {
    overflow-y: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 70vh;
    padding: 35px 15px; }
    .container .weather-container .header-container {
      width: 100%;
      height: 15%;
      display: flex;
      flex-direction: column;
      align-items: center; }
      .container .weather-container .header-container .dates-container {
        margin-top: 25px;
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-evenly; }
        .container .weather-container .header-container .dates-container .arrow {
          display: flex;
          align-items: center; }
    .container .weather-container .weather-list {
      height: 85%;
      width: 100%;
      display: flex;
      flex-direction: column;
      overflow-y: auto;
      padding: 0 15px; }
      .container .weather-container .weather-list .weather-day {
        width: 100%;
        min-height: 100px;
        display: flex;
        justify-content: space-between;
        align-items: center; }
        .container .weather-container .weather-list .weather-day .date-and-temp {
          display: flex;
          flex-direction: column; }
        .container .weather-container .weather-list .weather-day img {
          height: 25px;
          width: 25px; }
  .container .suggestions-container {
    overflow-y: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 70vh; }
    .container .suggestions-container .header-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 25%; }
      .container .suggestions-container .header-container .title {
        margin-top: 25px;
        margin-bottom: 5px; }
      .container .suggestions-container .header-container .address-container {
        display: flex;
        flex-direction: column;
        align-items: center; }
        .container .suggestions-container .header-container .address-container .address {
          margin-top: 8px; }
    .container .suggestions-container .atrractions-list {
      overflow-y: auto;
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 75%; }
      .container .suggestions-container .atrractions-list .attraction {
        min-height: 100px;
        width: 100%;
        display: flex;
        align-items: center; }
        .container .suggestions-container .atrractions-list .attraction .attraction-details {
          margin-left: 15px;
          display: flex;
          flex-direction: column; }
        .container .suggestions-container .atrractions-list .attraction a {
          margin-left: auto;
          margin-right: 10px; }
  .container .flight-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: auto; }
    .container .flight-container .title {
      margin: 35px 0 35px 0; }
    .container .flight-container .flight-details-container {
      width: 90%;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 35px; }
      .container .flight-container .flight-details-container .flight-details {
        display: flex;
        align-items: center;
        width: 90%;
        margin-bottom: 15px; }
        .container .flight-container .flight-details-container .flight-details .flight-title {
          width: 50%; }
        .container .flight-container .flight-details-container .flight-details .flag {
          margin-right: 25px; }
    .container .flight-container .flight-form {
      display: flex;
      align-items: center; }
    .container .flight-container .button {
      margin-top: auto;
      margin-bottom: 35px; }

@media screen and (max-device-width: 480px) {
  .form-container {
    display: flex;
    flex-direction: column;
    border: solid 1px #979797;
    background-color: white;
    width: 375px; }
    .form-container .divider {
      height: 1.5px;
      background-color: grey;
      opacity: 0.2; }
    .form-container .auth-header {
      margin: 8px 0 8px 0;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between; }
      .form-container .auth-header .logo-icon {
        margin-left: 8px;
        width: 35px;
        height: 35px; }
      .form-container .auth-header .close-btn {
        margin-right: 8px; }
    .form-container .auth-form-body {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center; }
      .form-container .auth-form-body .auth-title {
        text-align: center;
        margin: 40px 0;
        color: black; }
      .form-container .auth-form-body .auth-btn {
        margin-top: 6px;
        margin-bottom: 18px;
        opacity: 1;
        pointer-events: all;
        transition: background-color 0.4s ease;
        background-color: var(--main-blue);
        font-size: 18px;
        padding: 0 32px;
        height: 58px; }
      .form-container .auth-form-body .fade {
        transition: none;
        background-color: rgba(42, 183, 222, 0.403);
        pointer-events: none;
        box-shadow: none; }
      .form-container .auth-form-body .auth-forgot {
        color: var(--main-blue);
        margin-bottom: 18px;
        font-weight: 550; }
      .form-container .auth-form-body .failed {
        padding: 10px;
        margin-bottom: 40px;
        width: 70%;
        border: solid 1px red;
        border-radius: 1px;
        color: white !important;
        background-color: #e26a6a; }
      .form-container .auth-form-body .success {
        padding: 10px;
        margin-bottom: 40px;
        width: 70%;
        border: solid 1px green;
        border-radius: 1px;
        color: white !important;
        background-color: #7befb2; }
  .auth-footer {
    margin: 8px 0;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center; }
    .auth-footer .footer-text {
      font-size: 13px;
      font-weight: 550;
      color: black;
      margin-right: 5px; }
    .auth-footer .footer-link {
      color: var(--main-blue);
      font-size: 13px;
      font-weight: 550; } }
