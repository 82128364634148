.current-journey-container {
  z-index: 1;
  position: relative;
  display: flex;
  width: 100%;
  height: 228px;
  background-image: url("/static/images/rio-img.jpg");
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover; }
  .current-journey-container::before {
    z-index: 2;
    background-color: var(--journeys-background);
    position: absolute;
    content: "";
    width: 100%;
    height: 100%; }
  .current-journey-container .no-ongoing {
    color: black; }
  .current-journey-container .details-container {
    z-index: 3;
    display: flex;
    flex-direction: column;
    padding-top: 38px;
    padding-left: 32px;
    width: 75%; }
    .current-journey-container .details-container .title {
      display: flex;
      align-items: center;
      margin-bottom: 13px; }
      .current-journey-container .details-container .title .ongoing {
        margin-left: 13px;
        font-size: 18px;
        letter-spacing: 0.51px;
        color: #b88acf; }
  .current-journey-container .day-container {
    z-index: 3;
    padding-top: 21px;
    padding-left: 25px;
    padding-right: 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgba(166, 94, 202, 0.8);
    color: white;
    width: 25%; }
    .current-journey-container .day-container .day-activities {
      margin-top: 27px;
      width: 100%;
      display: flex;
      flex-direction: column; }
    .current-journey-container .day-container .day-activities > * {
      margin-bottom: 13px; }

.journeys-bar-container {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 30px; }
  .journeys-bar-container .title {
    margin-right: auto; }
  .journeys-bar-container .menu-surface {
    display: flex;
    flex-direction: column;
    padding: 15px;
    min-width: 300px;
    max-height: 500px !important; }
    .journeys-bar-container .menu-surface .clear {
      transition: opacity 0.25s ease-in-out;
      position: fixed;
      top: 15px;
      right: 15px;
      opacity: 0.6; }
      .journeys-bar-container .menu-surface .clear:hover {
        opacity: 1; }
    .journeys-bar-container .menu-surface .destinationsContainer {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      width: 100%;
      margin-bottom: 30px; }
      .journeys-bar-container .menu-surface .destinationsContainer .title {
        margin-bottom: 20px;
        opacity: 0.7;
        font-size: 15px;
        color: #4d4d4e; }
      .journeys-bar-container .menu-surface .destinationsContainer .destination-row {
        display: flex;
        align-items: center; }
        .journeys-bar-container .menu-surface .destinationsContainer .destination-row .checkbox {
          margin-right: 15px; }
          .journeys-bar-container .menu-surface .destinationsContainer .destination-row .checkbox div {
            border-radius: 0px; }
        .journeys-bar-container .menu-surface .destinationsContainer .destination-row .dest-text {
          text-transform: capitalize;
          font-size: 15px;
          color: #4d4d4e; }
    .journeys-bar-container .menu-surface .dates-container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      width: 100%;
      margin-bottom: 30px; }
      .journeys-bar-container .menu-surface .dates-container .title {
        margin-bottom: 20px;
        opacity: 0.7;
        font-size: 15px;
        color: #4d4d4e; }
      .journeys-bar-container .menu-surface .dates-container .date-row {
        box-sizing: border-box;
        display: flex;
        align-items: center;
        flex-direction: row;
        padding: 0 15px;
        width: 100%;
        margin-bottom: 15px; }
        .journeys-bar-container .menu-surface .dates-container .date-row .DayPickerInput {
          width: 100px; }
        .journeys-bar-container .menu-surface .dates-container .date-row .date-text {
          margin-right: auto;
          text-transform: capitalize;
          width: 40px;
          font-size: 15px;
          color: #4d4d4e; }
        .journeys-bar-container .menu-surface .dates-container .date-row input {
          min-width: none;
          width: 150px;
          font-size: 15px;
          background-image: none;
          color: #4d4d4e;
          border-bottom: solid 1px #4d4d4e; }
          .journeys-bar-container .menu-surface .dates-container .date-row input::placeholder {
            font-size: 15px;
            color: #4d4d4e; }
    .journeys-bar-container .menu-surface .days-container {
      display: flex;
      flex-direction: column; }
      .journeys-bar-container .menu-surface .days-container span {
        margin-bottom: 15px; }
      .journeys-bar-container .menu-surface .days-container input::placeholder {
        opacity: 0.5; }
  .journeys-bar-container .hide {
    display: none; }

.journeys-grid-container {
  display: flex;
  flex-wrap: wrap;
  height: auto;
  margin-right: -10px;
  margin-left: -10px; }
  .journeys-grid-container .no-journeys {
    height: 332px;
    text-align: center; }
  .journeys-grid-container .grid-img {
    z-index: 1;
    position: relative;
    display: flex;
    flex-direction: column;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: 332px;
    align-items: center;
    justify-content: center;
    padding: 0 38px;
    text-transform: capitalize;
    flex: 1 0 auto;
    margin-right: 10px;
    margin-left: 10px;
    margin-bottom: 20px; }
    .journeys-grid-container .grid-img::before {
      z-index: 2;
      background-color: var(--journeys-background);
      position: absolute;
      content: "";
      width: 100%;
      height: 100%; }
    .journeys-grid-container .grid-img span {
      z-index: 3;
      text-align: center; }
  .journeys-grid-container .grid-img:nth-child(1) {
    width: 227px; }
  .journeys-grid-container .grid-img:nth-child(2) {
    width: 429px; }
  .journeys-grid-container .grid-img:nth-child(3) {
    width: 523px; }
  .journeys-grid-container .grid-img:nth-child(4) {
    width: 306px; }
  .journeys-grid-container .grid-img:nth-child(5) {
    width: 201px; }
  .journeys-grid-container .grid-img:nth-child(6) {
    width: 287px; }
  .journeys-grid-container .grid-img:nth-child(7) {
    width: 272px; }
  .journeys-grid-container .grid-img:nth-child(8) {
    width: 306px; }
  .journeys-grid-container .grid-img:nth-child(9) {
    width: 430px; }
  .journeys-grid-container .grid-img:nth-child(10) {
    width: 211px; }
  .journeys-grid-container .grid-img:nth-child(11) {
    width: 558px; }
  .journeys-grid-container .grid-img:nth-child(12) {
    width: 305px; }
  .journeys-grid-container .grid-img:nth-child(13) {
    width: 377px; }
  .journeys-grid-container .grid-img:nth-child(14) {
    width: 255px; }
  .journeys-grid-container .grid-img:nth-child(15) {
    width: 302px; }
  .journeys-grid-container .grid-img:nth-child(16) {
    width: 210px; }
  .journeys-grid-container .grid-img:nth-child(17) {
    width: 352px; }
  .journeys-grid-container .grid-img:nth-child(18) {
    width: 299px; }
  .journeys-grid-container .grid-img:nth-child(19) {
    width: 326px; }
  .journeys-grid-container .grid-img:nth-child(20) {
    width: 203px; }
  .journeys-grid-container .grid-img:nth-child(21) {
    width: 364px; }
  .journeys-grid-container .grid-img:nth-child(22) {
    width: 291px; }
  .journeys-grid-container .grid-img:nth-child(23) {
    width: 535px; }
  .journeys-grid-container .grid-img:nth-child(24) {
    width: 557px; }
  .journeys-grid-container .grid-img:nth-child(25) {
    width: 537px; }
  .journeys-grid-container .grid-img:nth-child(26) {
    width: 282px; }
  .journeys-grid-container .grid-img:nth-child(27) {
    width: 537px; }
  .journeys-grid-container .grid-img:nth-child(28) {
    width: 589px; }
  .journeys-grid-container .grid-img:nth-child(29) {
    width: 527px; }
  .journeys-grid-container .grid-img:nth-child(30) {
    width: 220px; }
  .journeys-grid-container .grid-img:nth-child(31) {
    width: 433px; }
  .journeys-grid-container .grid-img:nth-child(32) {
    width: 566px; }
  .journeys-grid-container .grid-img:nth-child(33) {
    width: 387px; }
  .journeys-grid-container .grid-img:nth-child(34) {
    width: 578px; }
  .journeys-grid-container .grid-img:nth-child(35) {
    width: 539px; }
  .journeys-grid-container .grid-img:nth-child(36) {
    width: 279px; }

@media screen and (max-device-width: 480px) {
  .current-journey-container {
    width: auto;
    flex-direction: column;
    margin-top: 35px;
    height: 533px; }
    .current-journey-container .details-container {
      width: auto;
      padding: 30px 0 0 25px;
      height: 60%; }
      .current-journey-container .details-container .title .ongoing {
        font-size: 15px; }
    .current-journey-container .day-container {
      width: auto;
      height: 40%; }
  .journeys-bar-container {
    display: none;
    flex-direction: column; }
    .journeys-bar-container .menu-surface {
      min-width: 0;
      max-height: 0 !important; }
      .journeys-bar-container .menu-surface .clear {
        top: 25px; }
  .no-journeys {
    text-align: center; }
  .journeys-grid-container {
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    padding-bottom: 15px; }
    .journeys-grid-container .row-divider {
      height: 12px; }
    .journeys-grid-container .grid-img {
      width: 100% !important;
      height: 236px;
      padding: 0; }
  .footer {
    text-align: center;
    font-size: 14px;
    opacity: 0.5;
    font-weight: 600;
    letter-spacing: 0.25px;
    color: var(--grape-purple);
    margin-bottom: 15px; } }
