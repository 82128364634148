@media screen and (max-device-width: 480px) {
  .form-container {
    display: flex;
    flex-direction: column;
    border: solid 1px #979797;
    background-color: white;
    width: 375px; }
    .form-container .divider {
      height: 1.5px;
      background-color: grey;
      opacity: 0.2; }
    .form-container .auth-header {
      margin: 8px 0 8px 0;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between; }
      .form-container .auth-header .logo-icon {
        margin-left: 8px;
        width: 35px;
        height: 35px; }
      .form-container .auth-header .close-btn {
        margin-right: 8px; }
    .form-container .auth-form-body {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center; }
      .form-container .auth-form-body .auth-title {
        text-align: center;
        margin: 40px 0;
        color: black; }
      .form-container .auth-form-body .auth-btn {
        margin-top: 6px;
        margin-bottom: 18px;
        opacity: 1;
        pointer-events: all;
        transition: background-color 0.4s ease;
        background-color: var(--main-blue);
        font-size: 18px;
        padding: 0 32px;
        height: 58px; }
      .form-container .auth-form-body .fade {
        transition: none;
        background-color: rgba(42, 183, 222, 0.403);
        pointer-events: none;
        box-shadow: none; }
      .form-container .auth-form-body .auth-forgot {
        color: var(--main-blue);
        margin-bottom: 18px;
        font-weight: 550; }
      .form-container .auth-form-body .failed {
        padding: 10px;
        margin-bottom: 40px;
        width: 70%;
        border: solid 1px red;
        border-radius: 1px;
        color: white !important;
        background-color: #e26a6a; }
      .form-container .auth-form-body .success {
        padding: 10px;
        margin-bottom: 40px;
        width: 70%;
        border: solid 1px green;
        border-radius: 1px;
        color: white !important;
        background-color: #7befb2; }
  .auth-footer {
    margin: 8px 0;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center; }
    .auth-footer .footer-text {
      font-size: 13px;
      font-weight: 550;
      color: black;
      margin-right: 5px; }
    .auth-footer .footer-link {
      color: var(--main-blue);
      font-size: 13px;
      font-weight: 550; } }
