.tabs-container {
  display: flex;
  overflow: none;
  flex-wrap: nowrap;
  width: 100vw;
  margin-top: 3px; }
  .tabs-container .tabs-outer {
    min-width: calc(100vw - 65px); }
  .tabs-container .tabs-wrap {
    display: flex;
    background: #ffffff; }

.tab {
  min-width: 0;
  position: relative;
  height: 62px;
  width: 100%;
  background-color: #ffffff; }
  .tab-wrap {
    flex: 1 1 0px;
    max-width: 400px;
    overflow: hidden; }
    .tab-wrap-active {
      min-width: 200px; }
  .tab.plus {
    width: 65px; }
  .tab.loading {
    pointer-events: none;
    opacity: 0.5; }
  .tab:hover .tab-title {
    opacity: 1; }
  .tab-actions {
    opacity: 0; }
  .tab-title {
    box-sizing: border-box;
    opacity: 0.75;
    width: 100%;
    height: 100%;
    transition: opacity 0.25s ease;
    position: relative;
    display: flex;
    align-items: center;
    color: var(--main-pink);
    padding: 0 0px 0 15px;
    letter-spacing: 0.5px;
    font-size: 16px;
    border-right: solid 4px #f0eef1; }
    .tab-title-text {
      white-space: nowrap;
      min-height: 19px;
      max-height: 38px;
      display: inline-block;
      overflow: hidden !important;
      text-overflow: ellipsis;
      width: 100%; }
    .tab-title .notification-rect {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      bottom: 10px;
      left: 10px;
      width: 20px;
      height: 14px;
      border-radius: 6px;
      box-shadow: 0 2px 4px 0 rgba(108, 108, 108, 0.2);
      background-color: #ffffff;
      color: var(--rosy-pink);
      font-size: 10px;
      font-weight: bold; }
    .tab-title .icon {
      position: relative;
      margin-right: 16.9px;
      display: flex;
      justify-content: center;
      align-items: center; }
      .tab-title .icon .icon-img {
        fill: var(--rosy-pink); }
  .tab-menu {
    width: 150px;
    /* right: 0;
      left: auto!important; */ }
  .tab-edit-input {
    display: flex;
    align-items: center;
    flex: 1; }
    .tab-edit-input input {
      width: 100%;
      margin-left: 15px;
      background-image: none;
      color: var(--main-purple);
      font-size: 14px;
      padding: 15px 0 15px 5px;
      font-weight: normal; }
    .tab-edit-input i {
      margin-right: -11px; }
  .tab-new-input {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    align-items: center; }
    .tab-new-input input {
      width: 100%;
      background-image: none;
      color: var(--main-pink);
      font-size: 16px;
      padding: 15px 0 15px 5px;
      font-weight: normal; }
      .tab-new-input input::placeholder {
        font-size: 16px;
        color: var(--main-pink); }
    .tab-new-input i {
      margin-right: -11px; }
  .tab .menu-open {
    display: inline-block; }
  .tab:hover {
    cursor: pointer; }
  .tab:hover .tab-actions {
    opacity: 1; }
  .tab-is-active {
    background-color: #f0eef1; }
    .tab-is-active .tab-title {
      opacity: 1;
      color: var(--main-purple); }
    .tab-is-active:hover {
      cursor: pointer; }

.add-new-tab {
  cursor: auto;
  min-width: 200px !important;
  max-width: 400px !important; }

.add-item-btn {
  transition: opacity 0.25s ease;
  display: flex;
  text-align: left;
  padding: 0px 15px;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  background-color: #FFFFFF;
  color: var(--main-pink);
  align-items: center;
  justify-content: center;
  height: 62px;
  opacity: 0.65;
  width: 35px; }
  .add-item-btn:hover {
    cursor: pointer;
    opacity: 1; }

.collapse-btn {
  transition: transform 0.8s;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: auto;
  height: 62px;
  min-width: 35px;
  padding: 0px 15px;
  text-align: center;
  background-color: #ffffff;
  color: var(--main-pink);
  display: flex;
  align-items: center;
  justify-content: center; }
  .collapse-btn svg {
    transition: opacity 0.35s, transform 0.3s;
    opacity: 0.65; }
  .collapse-btn:hover {
    cursor: pointer; }
    .collapse-btn:hover svg {
      opacity: 1; }
  .collapse-btn.rotate-down svg {
    transform: rotate(-180deg); }

@media screen and (max-device-width: 480px) {
  .tabs-container {
    flex-wrap: wrap; }
    .tabs-container .tabs-col {
      margin-bottom: 2px;
      height: 66px;
      width: 100%; }
    .tabs-container .tabs-outer {
      min-width: 100%; }
    .tabs-container .tabs-wrap {
      display: inline; }
  .add-new-tab {
    min-width: 100% !important;
    max-width: 100% !important; }
  .add-tab {
    height: 62px;
    width: 100%;
    background-color: #ffffff;
    display: flex;
    align-items: center; }
    .add-tab .new-text {
      color: #762eac;
      font-weight: bold;
      margin-left: 22px;
      font-size: 14px;
      letter-spacing: 0.25px; }
    .add-tab .plus-btn {
      margin-left: auto;
      margin-right: 19.5px; }
  .tab {
    border: none !important;
    width: 100% !important; }
    .tab-wrap {
      max-width: 100%; }
    .tab-title {
      border: none;
      opacity: 1;
      flex-wrap: nowrap;
      text-align: center;
      justify-content: flex-start;
      color: var(--main-pink);
      padding: 12px 0px 12px 33.8px; }
      .tab-title-text {
        white-space: nowrap;
        text-align: left;
        text-overflow: ellipsis;
        width: 100%;
        max-width: 200px; }
    .tab-actions {
      display: inline-block;
      margin-left: auto; }
      .tab-actions .right-arrow {
        margin-right: 21.5px; }
    .tab-is-active .tab-title {
      padding-left: 25px; }
      .tab-is-active .tab-title .icon {
        opacity: 1;
        margin-right: 22px;
        margin-left: auto; }
  .add-item-btn {
    max-width: 20px; } }
