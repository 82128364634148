.container {
  margin-left: -8px;
  margin-right: -8px;
  height: 66px;
  display: flex;
  align-items: center;
  background-color: #ffffff; }
  .container .left-arrow {
    margin-left: 18px; }
  .container .text {
    margin-left: 18px;
    font-size: 14px;
    font-weight: bold; }
  .container .icon {
    margin-left: auto;
    margin-right: 22px; }
