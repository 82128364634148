@media screen and (min-device-width: 481px) {
  .ads-container {
    padding-top: 90px;
    display: flex;
    flex-direction: column; }
    .ads-container .ad {
      height: 120px;
      background-color: #ffffff;
      box-shadow: 0 6px 6px 0 rgba(71, 15, 99, 0.05), 0 2px 0 0 rgba(240, 238, 241, 0.24); }
      .ads-container .ad:first-child {
        border-bottom: solid 2px #e4e0e8; }
      .ads-container .ad .first-line {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 15px; }
      .ads-container .ad .link-line {
        display: flex;
        align-items: center;
        margin: 0 0 15px 15px; } }
