.container {
  width: 50vw;
  padding: 36px 38px 38px 50px;
  text-align: center; }
  .container .divider {
    border: solid 1px rgba(151, 151, 151, 0.15);
    margin-top: 29px;
    margin-bottom: 48px; }
  .container .form-container {
    margin: 0 !important;
    display: flex;
    flex-wrap: wrap; }
    .container .form-container .form-col {
      display: flex;
      width: 100%;
      align-items: center;
      margin-bottom: 34px; }
      .container .form-container .form-col span:first-child {
        width: 100px;
        font-weight: 500; }
      .container .form-container .form-col input {
        padding: 0 !important; }
    .container .form-container .button-bar {
      padding: 0 200px;
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center; }

@media screen and (max-device-width: 480px) {
  .container {
    width: 315px;
    position: relative;
    padding: 15px 10px 15px 10px; }
    .container .form-container {
      position: relative;
      width: 100%;
      flex-wrap: nowrap;
      flex-direction: column; }
      .container .form-container .form-col span {
        text-align: left; }
      .container .form-container .button-bar {
        padding: 0 50px; } }
