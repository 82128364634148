.container {
  overflow-x: none;
  overflow-y: auto;
  height: calc(100vh - 166px); }

.wrapper {
  height: 100%;
  padding-left: 15px;
  padding-right: 15px;
  position: relative; }

.timeline {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  padding: 1em 0 1em 0;
  align-items: start; }
  .timeline p {
    margin-top: 4px; }
  .timeline:first-child {
    padding-top: 40px; }
  .timeline:last-child {
    padding-bottom: 40px; }
  .timeline-img {
    align-items: center;
    display: flex;
    justify-content: center;
    background: #dcd7e2;
    border-radius: 50%;
    width: 38px;
    height: 38px;
    position: relative;
    z-index: 1;
    order: 1;
    margin-top: -9px; }
  .timeline-date {
    order: 0;
    width: 150px;
    padding: 0 19px 0 0;
    text-align: left; }
    .timeline-date .day, .timeline-date .month {
      padding-left: 20px;
      font-weight: bold; }
    .timeline-date .day {
      margin-top: -9px;
      font-size: 30px; }
    .timeline-date span {
      display: block;
      width: 100%; }
  .timeline-content {
    order: 2;
    flex: 1;
    padding: 0 0 0 80px;
    position: relative; }
    .timeline-content .event-buttons {
      position: absolute;
      top: 0px;
      right: 0px;
      padding-top: 4px;
      padding-right: 8px; }
      .timeline-content .event-buttons .button {
        height: 20px;
        width: 20px;
        margin-left: 10px;
        opacity: 0.75;
        transition: opacity .20s ease-in; }
        .timeline-content .event-buttons .button:hover {
          opacity: 1;
          cursor: pointer;
          transition: opacity .20s ease-in; }
    .timeline-content .description {
      width: 100%;
      margin-top: 20px;
      border-radius: 16px;
      box-shadow: 0 4px 4px 0 rgba(71, 15, 99, 0.05);
      background-color: #ffffff; }
    .timeline-content::before {
      content: '';
      position: absolute;
      margin-top: 8px;
      border-top: 4px solid #dcd7e2;
      width: 56px;
      left: -2px;
      border-radius: 10px; }
    .timeline-content .line-wrapper {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      top: 24px;
      left: -38px;
      width: 38px;
      height: 100%; }
      .timeline-content .line-wrapper .line {
        width: 4px;
        height: 100%;
        background: #dcd7e2; }
      .timeline-content .line-wrapper .add-new {
        display: none;
        cursor: pointer;
        width: 38px;
        height: 38px;
        border-radius: 50%;
        background-color: #f86c77;
        position: absolute;
        align-items: center;
        justify-content: center;
        color: #fff;
        font-size: 25px;
        font-weight: 900; }
      .timeline-content .line-wrapper:hover .add-new {
        display: flex; }

.suggestion {
  color: #f86c77; }
  .suggestion .timeline-img {
    background-color: #f86c77; }
  .suggestion .timeline-content::before {
    border-top: 4px dashed #f86c77; }
  .suggestion .timeline-content .event-type {
    color: #f86c77; }
  .suggestion .timeline-content .line-wrapper .line {
    background: none;
    border-right: dashed #f86c77;
    margin-right: 4px; }

.timeline.question {
  color: var(--main-blue);
  font-weight: bold; }
  .timeline.question .timeline-img {
    background-color: var(--main-blue); }
  .timeline.question .timeline-content .event-type {
    color: var(--main-blue); }
  .timeline.question .timeline-content .description {
    margin-top: 22px; }
  .timeline.question .timeline-content::before {
    border-top: 4px dashed var(--main-blue); }
  .timeline.question .timeline-content .line-wrapper .line {
    background: none;
    border-right: dashed var(--main-blue);
    margin-right: 4px; }
  .timeline.question .timeline-content .line-wrapper .add-new {
    display: none; }
    .timeline.question .timeline-content .line-wrapper .add-new:hover {
      display: none; }

.next-question {
  padding: 20px 0 20px 0;
  width: 100%;
  display: flex;
  align-items: center; }
  .next-question .wrapper {
    display: flex;
    align-items: center;
    width: 100%; }
    .next-question .wrapper .question {
      display: flex;
      align-items: center; }
      .next-question .wrapper .question .text {
        padding: 0 12px 0 24px; }
      .next-question .wrapper .question .approve-btn {
        display: inline-block;
        margin-top: 20px; }
        .next-question .wrapper .question .approve-btn svg {
          vertical-align: middle;
          margin-left: 20px;
          cursor: pointer; }
    .next-question .wrapper .edit {
      display: flex;
      margin-left: auto;
      margin-right: 24px; }
      .next-question .wrapper .edit .story-btn {
        margin-left: auto; }

@media screen and (max-device-width: 480px) {
  .container {
    width: 100%;
    height: calc(100vh - 154px);
    margin: 0;
    padding: 0 16px; }
  .wrapper {
    width: 100%;
    height: calc(100vh - 154px);
    padding: 0; }
  .timeline {
    text-align: center;
    flex-direction: column;
    align-items: center;
    justify-content: center; }
    .timeline p {
      margin: 0; }
    .timeline-img {
      margin: 0; }
      .timeline-img::after {
        content: '';
        background-color: #dcd7e2;
        position: absolute;
        border: 1px solid transparent;
        width: 2px;
        height: 32px;
        top: 95%;
        border-radius: 10px; }
    .timeline-date {
      width: auto;
      display: flex;
      text-align: center;
      margin-bottom: 11px;
      padding: 0; }
      .timeline-date .day, .timeline-date .month {
        padding: 0; }
      .timeline-date .day {
        font-size: 14px;
        margin: 0;
        padding-right: 5px; }
    .timeline-content {
      padding: 0;
      order: 2;
      margin-top: 43px;
      width: 100%; }
      .timeline-content::before {
        content: none !important; }
      .timeline-content p {
        text-align: center; }
  .suggestion .timeline-img::after {
    background: repeating-linear-gradient(to bottom, #f86c77 0, #f86c77 5px, transparent 5px, transparent 9px); }
  .suggestion .timeline-content::before {
    content: none !important; }
  .timeline.question .timeline-img::after {
    background: var(--main-blue); }
  .timeline.question .timeline-content::before {
    content: none !important; }
  .next-question {
    flex-direction: column; }
    .next-question .wrapper .question .approve-btn {
      margin-top: 0; } }
