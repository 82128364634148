.ad-background {
  display: flex;
  align-items: center;
  width: 89%;
  background-color: #f0eef1; }

.hrefText {
  cursor: pointer;
  color: var(--rosy-pink);
  font-size: 14px;
  letter-spacing: 0.35px;
  line-height: 22px;
  margin-left: 19px; }

.ad-icon {
  display: flex;
  align-items: center;
  width: 32px;
  height: 32px;
  object-fit: contain;
  margin-left: auto;
  margin-right: 12.7px; }

.close-icon {
  cursor: pointer;
  margin-right: 17.3px;
  width: 13px;
  height: 13px;
  object-fit: contain; }
